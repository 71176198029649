import { IMessage, IMessageBus } from '@roketus/web-toolkit';
import { filter } from 'rxjs';
import {
  DEMO_EMAIL_UPDATE_CLICK,
  DEMO_LOGOUT_CLICK,
  DEMO_NAME_UPDATE_CLICK,
  DEMO_PHONE_UPDATE_CLICK,
  DEMO_PROFILE_CONTINUE_CLICK,
  DEMO_PROFILE_SET_CLICK,
} from '../domain/specs/analyticsActionCodes';
import { IUserAnalyticsService } from '../boundries/IUserAnalyticsService';

const isAnalyticsMessage = (value: IMessage): boolean =>
  [
    DEMO_NAME_UPDATE_CLICK,
    DEMO_PHONE_UPDATE_CLICK,
    DEMO_EMAIL_UPDATE_CLICK,
    DEMO_PROFILE_SET_CLICK,
    DEMO_LOGOUT_CLICK,
    DEMO_PROFILE_CONTINUE_CLICK,
  ].includes(value.message);

export const init = (messageBus: IMessageBus): IUserAnalyticsService => {
  let user_id: string | undefined;
  const gtag = window.gtag;

  messageBus.stream$.pipe(filter(isAnalyticsMessage)).subscribe((msg) => {
    const message = msg as IMessage;
    const send = (eventName: string, value?: string) => {
      console.log('$$sending', 'event', eventName, {
        value: value,
      });

      gtag('event', eventName, {
        value: value,
      });
    };

    return send(message.message);
  });

  return {
    setUserID(userId: string) {
      gtag('set', { user_id: userId });
      user_id = userId;
    },
    getUserID() {
      return user_id || '';
    },
  };
};
